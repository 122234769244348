<!-- 文章详情 -->
<template>
    <div class="page-art-det f-wrap">
        <div class="article-info">
            <div class="title">{{ArticleInfo.title}}</div>
            <div class="time">
                <div class="f-ibm" style="margin-right:10px"><span class="lable">发布时间：</span>{{ArticleInfo.generate_time}}</div>
                <div class="f-ibm yuan">来源：安贸通</div>
            </div>
        </div>
        <div class="article-cont" v-html="ArticleInfo.content"></div>
        <div class="article-next">
            <div class="previous" v-if="ArticleInfo.previous">
                <div class="text f-ibm"  @click="requestGetContentDetails(ArticleInfo.previous.id)">上一篇：{{ArticleInfo.previous ? ArticleInfo.previous.title : ''}}</div>
            </div>
            <div class="next" v-if="ArticleInfo.next" >
                <div  class="text f-ibm" @click="requestGetContentDetails(ArticleInfo.next.id)">下一篇：{{ArticleInfo.next ? ArticleInfo.next.title : ''}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { indexApi } from "@/api/module.js";
export default {
    components: {},
    name: "articDetails",
    data() {
        return {
            ArticleInfo: {}, //帮助中心列表
        };
    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
        this.requestGetContentDetails(this.$route.query.id);
    },
    methods: {
        requestGetContentDetails(id) {
            this.$router.push({
                query: { id: id },
            });
            const self = this;
            self.GoodsClassList = [];
            indexApi
                .getContentDetails({ id: id })
                .then((res) => {
                    if (res.data.data) {
                        self.ArticleInfo = res.data.data;
                        console.log();
                    }
                });
        },
    },
};
</script>
<style>
body{
    background-color: white;
}
</style>
<style lang="less" scoped>
    .page-art-det{
        border: 1px solid #e5e5e5;
        padding: 30px;
        margin-top: 24px;
        .article-info{
            text-align: center;
            border-bottom: 1px solid #e5e5e5;
                margin-bottom: 20px;
            .title{
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #222222;
                line-height: 30px;
            }
            .time{
                margin: 20px 0 25px;
                height: 30px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999898;
                line-height: 30px;
            }
        }
        .article-cont{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #444444;
            line-height: 30px;
            overflow: hidden;
            max-width: 100%;
        }
        .article-cont *,
        .article-cont img{
            max-width: 100% !important;
        }
        .article-next{
            border-top: 1px solid #e5e5e5;
            margin-top: 37px;
            padding-top: 27px;
            .previous,.next{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 36px;
                cursor: pointer;
                .text{
                    &:hover{
                    text-decoration: underline;
                    color: #F02420;
                }
                }
                
            }

        }
    }

//移动处理
@media screen and (max-width:750px) { 
    
    .page-art-det{
        border: none;
        border-top: 1px solid #e5e5e5;
        padding: 12px 15px 30px;
        box-sizing: border-box;
        margin: 0;
        .article-info{
            text-align: center;
            border-bottom: 1px solid #e5e5e5;
                margin-bottom: 20px;
            .title{
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #222222;
                line-height: 30px;
                text-align: left;
            }
            .time{
                text-align: left;
                margin: 0;
                padding: 12px 0 18px;
                .lable{
                    display: none;
                }
            }
            .yuan{
                display: none;
            }
        }
        .article-cont{
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: 24px;
        }
        .article-next{
            display: none;
        }
    }
}
</style>